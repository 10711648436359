import React from 'react'
import { BrowserRouter,Routes,Route } from 'react-router-dom'
import SignIn from '../component/SignIn'
import SignUp from '../component/SignUp'


export default function RouterConfig() {
    return (
        <BrowserRouter>

            <Routes>

                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />

            </Routes>

        </BrowserRouter>
    )
}
