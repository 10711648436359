import React, { useEffect, useState } from 'react';
import { supabase } from '../lib/helper/supabaseClient';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';

export default function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const [session, setSession] = useState(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
console.info(subscription);
    
  }, [])


  if (session && session.access_token && location.state && location.state.sessionData) {
    return (
      <>
        <h1>Email Address: {location.state.sessionData}</h1>
      </>)
  }
  else {
    return (
      navigate('/SignUp')
    )
  }
}