import RouterConfig from './navigation/RouterConfig.js';
import './App.css'
export default function App() {
  
  return (
    <>
      <RouterConfig></RouterConfig>
    </>
  )
}
