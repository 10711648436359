import React, { useEffect, useState } from 'react';
import { supabase } from '../lib/helper/supabaseClient';
import { useNavigate } from 'react-router'; 


export default function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
  
    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };
  
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };
  
    const login = async () => {
      try {
        const { data, error } = await supabase.auth.signUp({
          email,
          password,
        });
setUser(data.session.access_token);

if(data.session.access_token){

  navigate('/SignIn',{state: { sessionData: JSON.stringify(data) } });
}else{


}
  


        if (error) {
          console.error('Error signing up:', error.message);
        } else {
          console.log('Sign up successful. User:', data);
          // You can perform additional actions after successful sign-up
        }
      } catch (error) {
        console.error('Error signing up:', error.message);
      }
    };
  




  useEffect(() => {
    const session = supabase.auth.getUser();
    setUser(session?.user);
  }, [])

  return (
   
    <div className="container">
      {user?(<h1>Sucess</h1>):(<h1>Failed</h1>)}
      <h1>Sign Up</h1>
      <p>Please fill in this form to create an account.</p>
      <hr />

      <label htmlFor="email">
        <b>Email</b>
      </label>
      <input
        type="text"
        placeholder="Enter Email"
        name="email"
        value={email}
        onChange={handleEmailChange}
        required
      />
      <br />
      <label htmlFor="psw">
        <b>Password</b>
      </label>
      <input
        type="password"
        placeholder="Enter Password"
        name="psw"
        value={password}
        onChange={handlePasswordChange}
        required
      />
      <br />

      <div className="clearfix">
        <button onClick={login} className="signupbtn">
          Sign Up
        </button>
      </div>
    </div>
  );




  
}
